import React from "react";
import SectionTitle from "../components/SectionTitle";

const PrivacyPolicy = () => {
  return (
    <div className="wrapper">
      <SectionTitle title={"Privacy Policy Page"} />
      <div className="mb-2 md:text-[18px] flex flex-col gap-10">
        <div>
          asponlinemarket.com এ আপনাকে স্বাগতম। আমরা আমাদের ওয়েবসাইট
          ব্যবহারকারীদের গোপনীয়তা রক্ষায় সদা তৎপর এবং তাই আপনাদের তথ্যের
          গোপনীয়তা রক্ষায় আমরা একটি গোপনীয়তা নীতিমালায় প্রকাশ করছি যেখানে আমরা
          কিভাবে আপনাদের গোপনীয়তা রক্ষা করবো তার বিস্তারিত ভাবে বর্ণণা করা
          হয়েছে। এই নীতিমালায় আমরা কিভাবে আপনার তথ্য সংগ্রহ ও ব্যবহার করি,
          কিভাবে আমরা আপনার ব্যক্তিগত তথ্যের সুরক্ষা নিশ্চিত করি তারও বিস্তারিত
          বর্ণনা রয়েছ। আমরা শুধুমাত্র আমাদের ওয়েবসাইট ব্যবহার করে অর্ডার করতে যে
          তথ্যগুলো প্রয়োজন সেই তথ্যগুলোই সংগ্রহ করে থাকি। বিস্তারিত জানতে
          অনুগ্রহ করে সময় নিয়ে গোপনীয়তা নীতিমালাটি পড়ুন।
        </div>
        <div>
          <span className="font-semibold">ব্যক্তিগত তথ্যঃ</span> আপনি যখন আমাদের
          ওয়েবসাইটে রেজিস্ট্রেশন করেন তখন আপনার ফোন নাম্বার নেয়া হয়। পরবর্তীতে
          আপনি যখন অর্ডার দিতে যান তখন আপনার নাম, ফোন নাম্বার, ইমেইল এড্রেস,
          ঠিকানা এবং অনলাইন পেমেন্টের ক্ষেত্রে আপনার কার্ড সংক্রান্ত তথ্য নেয়া
          হয়। আপনার যাবতীয় তথ্য আমরা নিরাপদ ও সুরক্ষিত সার্ভারে সংগ্রহ করি
          ম্যালওয়ার স্ক্যান, হ্যাক স্ক্যান ইত্যাদি সিকিউরিটি টুলসের মাধ্যমে
          সর্বোচ্চ নিরাপত্তা নিশ্চিত করে থাকি।
        </div>
        <div>
          <span className="font-semibold">লেনদেন সংক্রান্ত তথ্যঃ</span>অনলাইন
          লেনদেনের ক্ষেত্রে ডেবিট কিংবা ক্রেডিট কার্ডের ব্যবহার বৃদ্ধির সাথে
          সাথে প্রতারক চক্রের সংখ্যাও বেড়ে চলেছে আর তাই আপনাদের তথ্যের নিরাপত্তা
          নিশ্চিত করতে আমরা আমাদের ওয়েব সাইটে অর্ডার করলে কোন কাস্টমার সম্পূর্ণ
          ক্যাশ অন ডেলিভারিতে সারা বাংলাদেশে প্রোডাক্ট নিতে পারবে ।
        </div>
        <div>
          <span className="font-semibold">তথ্য সরবরাহঃ</span> আমরা আপনার
          ব্যক্তিগত তথ্য অন্য কোনো ব্যক্তি বা প্রতিষ্ঠান কে প্রদান করি না।
          শুধুমাত্র আপনার অর্ডারকৃত পণ্য কুরিয়ার করার ক্ষেত্রে কুরিয়ার সার্ভিস
          কোম্পানিকে দেয়া হয়। সেইক্ষেত্রে কুরিয়ার সার্ভিস কোম্পানি তাদের
          ব্যবসায়িক প্রমোশন বা অন্য কাজে এই তথ্য ব্যবহার করলে তার দায়ভার
          সম্পূর্ণ আমাদের দায়বদ্ধতার বাইরে। তবে দেশের আইন প্রযোগকারী কোন সংস্থা
          যদি দেশের কাজে তাদের কোন তদন্তের প্রয়োজনে তথ্য চায় সেই ক্ষেত্রে আমরা
          দিতে বাধ্য থাকবো।
        </div>
        <div>
          <span className="font-semibold">কুকিসঃ</span> কুকিস হচ্ছে ছোট একটি
          ফাইল যা ব্রাউজারের মাধ্যমে আপনার সম্মতি নেয়ার মাধ্যমে আপনার হার্ড
          ড্রাইভে সংগ্রহ করি। আমরা কুকিস সংগ্রহ করার মাধ্যমে আমরা আপনি কোন
          ব্রাউজারটি ব্যবহার করছেন তা চিনতে পারি, আপনার পরিচয় বুঝতে পারি যাতে
          করে ভবিষ্যতে আপনাকে আরো ভালো ওয়েবসাইট এক্সপেরিয়েন্স দিতে পারি। আমাদের
          ওয়েবসাইটের বাইরেও অন্য কোনো তৃতীয় পক্ষের কুকিস আমাদের সাইট ব্যবহারের
          সময় আপনি পেতে পারেন (যেমন আপনি যদি আমাদের ওয়েবসাইট থেকে অন্য কারো তৈরি
          করা ওয়েব পেইজে ব্রাউজ করেন, তার কুকিস চলে আসতে পারে)। আমরা এই
          ওয়েবসাইটের ব্যবহার পর্যবেক্ষণ করার জন্য গুগল অ্যানালিটিক্স ব্যবহার করে
          থাকি। গুগল অ্যানালিটিক্স আপনার কম্পিউটারে কুকিস সংরক্ষণ করে বিভিন্ন
          পরিসংখ্যান ও অন্যান্য তথ্য আমাদেরকে প্রদান করে। আমাদের ওয়েবসাইট
          সংক্রান্ত এসব তথ্য ওয়েবসাইটের ব্যবহার সম্পর্কিত রিপোর্ট তৈরিতে সহায়তা
          করে।
        </div>
        <div>
          <span className="font-semibold">গোপনীয়তা নীতিমালা পরিবর্তনঃ</span>এই
          গোপনীয়তা নীতিমালাটি asponlinemarket.com যেকোন সময়ে পরিবর্তন করার
          অধিকার রাখে। আমাদের গোপনীয়তা নীতিমালায় যদি কোন পরিবর্তন আনা হয়, সেই
          ক্ষেত্রে পরিবর্তিত গোপনীয়তা নীতিমালাটি আমরা এখানে রাখবো।
        </div>
        <div>
          <span className="font-semibold">আপনার সম্মতিঃ</span> আমাদের ওয়েবসাইট
          ব্যবহার করার মাধ্যমে আপনি এই গোপনীয়তা নীতিমালায় সম্মতি প্রকাশ করছেন।
          আমাদের গোপনীয়তা নীতিমালা নিয়ে আপনার যদি কোন প্রশ্ন বা জিজ্ঞাসা থেকে
          থাকে তাহলে আমাদের সাথে যোগাযোগ করার অনুরোধ করা হলো। আপনার সম্মতিঃ
          আমাদের ওয়েবসাইট ব্যবহার করার মাধ্যমে আপনি এই গোপনীয়তা নীতিমালায় সম্মতি
          প্রকাশ করছেন। আমাদের গোপনীয়তা নীতিমালা নিয়ে আপনার যদি কোন প্রশ্ন বা
          জিজ্ঞাসা থেকে থাকে তাহলে আমাদের সাথে যোগাযোগ করার অনুরোধ করা হলো।
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
