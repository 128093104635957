import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../components/Card";

const SubCategoryPage = () => {
  const [subCat, setSubCat] = useState([]);
  console.log(subCat);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-subcategories/${id}`
        );
        const data = await res.json();
        setSubCat(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div className="min-h-screen">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-3 gap-2 md:gap-5">
        {subCat.length > 0 ? (
          subCat?.map((product) => (
            <div key={product?.id}>
              <div className="overflow-hidden">
                <Card product={product} />
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-full text-center">
            <span> No Prodict Found</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubCategoryPage;
