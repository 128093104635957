import React from "react";
import Card from "../components/Card";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

const OfferPage = () => {
  const { items: data } = useSelector((state) => state.offerProduct);

  // const { items: data } = useSelector((state) => state.category);

  return (
    <div className="px-3 pt-7 min-h-screen">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5  gap-2 md:gap-5">
        {data.length > 0 ? (
          data?.map((product) => (
            <div key={product.id}>
              <div className="overflow-hidden">
                <Card product={product} />
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-full  text-center">
            <span> No Product Found</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferPage;
