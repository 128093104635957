import { useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCall } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { BsCart3 } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";

import { IoIosClose, IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { BiUserCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Search from "./SearchField";
import { useEffect } from "react";
import axios from "axios";

const Navbar = ({ handleMenu }) => {
  const [searchItem, setSearchItem] = useState("");
  const [toggle, setToggle] = useState(false);
  const inputField = useRef(null);
  const { items: data } = useSelector((state) => state.category);
  const { cartItems: product } = useSelector((state) => state.cart);

  const { items: logo } = useSelector((state) => state.logo);
  console.log(logo);
  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#083344" : null,
      Font: isActive ? "font-bold" : null,
    };
  };
  const Navigate = useNavigate();

  const [searchData, setSearchData] = useState([]);
  const [word, setWord] = useState("");
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    const fetchSearchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api-products`);
      return setSearchData(res.data);
    };
    fetchSearchData();
  }, []);

  const handleChange = (e) => {
    const searchWord = e.target.value;
    setWord(searchWord);
    const newFilterData = searchData.filter((value) =>
      value.name.toLowerCase().includes(searchWord.toLowerCase())
    );

    if (searchWord === "") {
      setFilterData([]);
    } else {
      setFilterData(newFilterData);
    }
  };

  const handleClose3 = () => {
    setWord();
    setFilterData([]);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    Navigate("/shop");

    //form reset
    setSearchItem("");
    inputField.current.blur();
  };

  const search = (e) => {
    Navigate("/shop");
    setSearchItem(e.target.value);
  };

  // const handleMenu = () => {
  //   setToggle((prev) => !prev);
  // };
  const handleClose = () => {
    setToggle((prev) => !prev);
  };
  return (
    <>
      <div className="sticky px-4 md:px-0 -top-20 md:top-0 md:left-0  md:right-0 z-[9999] bg-white">
        {/* middle nav */}
        <div className="bg-white/75 py-5">
          <div className="mx-0 md:mx-5 my-2 flex items-center justify-between">
            <Link
              to={"/"}
              className="brand inline-block w-24 text-base md:text-2xl italic font-semibold rounded-md overflow-hidden"
            >
              <img
                src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                className="w-full h-full object-cover"
                alt=""
              />
            </Link>
            {/* search button */}
            <div className="hidden md:block">
              <form
                onSubmit={handleSearch}
                className="relative flex items-center justify-center"
              >
                {/* <input
                  ref={inputField}
                  type="text"
                  value={searchItem}
                  placeholder="Search Items......"
                  onChange={(e) => setSearchItem(e.target.value)}
                  className=" bg-transparent border p-3 px-8  w-[20rem] rounded-full focus:outline-none "
                /> */}
                {/* <button className="absolute  rounded-full font-extrabold text-black/70 py-2 px-4 top-[10px] right-2">
                  <FiSearch />
                </button> */}
                <Search />
              </form>
            </div>
            <div className="hidden md:block">
              <div className=" text-slate-400  text-[0.9rem] flex items-center gap-5 duration-1000">
                <div className="flex gap-1 items-center ">
                  <MdOutlineCall />
                  <p>
                    Help line <a href="tel://+8801935381540">+8801935-381540</a>
                  </p>
                </div>

                {/* <Link className=" hover:underline underline-offset-2 duration-300 ">
                  Login
                </Link>
                <span className="text-slate-400/40">or</span>
                <Link className=" hover:underline underline-offset-2 duration-300 ">
                  Registration
                </Link> */}

                <Link to={`${process.env.REACT_APP_URL}/dashboard`}>
                  <BiUserCircle className="text-3xl font-light" />
                </Link>

                <div className="hidden md:block col-span-2">
                  <div className="flex gap-5 items-center justify-end">
                    <div className="bg-indigo-500 text-white  p-4 shadow-lg">
                      <Link to="/addtocart" className=" relative group">
                        <BsCart3 className="text-3xl font-light" />
                        <span className=" absolute bottom-6 text-sm left-6 bg-[#c3b816] h-5 w-5 flex items-center justify-center rounded-full text-white group-hover:bg-sky-500 duration-300">
                          {product.length}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Link to="/addtocart" className=" block md:hidden relative group">
              <BsCart3 />
              <span className=" absolute bottom-2 text-sm left-2 text-slate-50 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full group-hover:bg-sky-500 duration-300">
                {product.length}
              </span>
            </Link>
          </div>

          {/* search button */}
          <div className="block md:hidden">
            <form
              onSubmit={handleSearch}
              className="relative flex flex-col items-center justify-center"
            >
              <input
                ref={inputField}
                type="text"
                placeholder="Search Items......"
                onChange={handleChange}
                className=" bg-transparent border p-3 w-full border-red-500   rounded-md focus:outline-none"
              />
              {filterData.length === 0 ? (
                <FiSearch className="absolute right-3 text-xl text-gray-500" />
              ) : (
                <IoIosClose
                  onClick={handleClose3}
                  className="absolute right-3  text-2xl text-gray-500"
                />
              )}
              {filterData.length !== 0 && (
                <div className="search-content flex flex-col w-full z-[9999] gap-3 bg-white h-auto  p-2 overflow-hidden overflow-y-scroll">
                  {filterData.slice(0, 5).map((product) => (
                    <Link
                      onClick={handleClose3}
                      key={product.id}
                      to={`/productdetails/${product.id}`}
                      className="product-card flex items-center    gap-2"
                    >
                      <div className="product-image w-20 h-20 overflow-hidden">
                        <img
                          src={`${process.env.REACT_APP_URL}/uploads/product/${product.photos}`}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="product-content text-black w-full text-sm overflow-hidden">
                        <h4 className="font-semibold">{product?.brand_name}</h4>
                        <p className="truncate text-lg ">{product?.name}</p>
                        <span className="text-gray-400">
                          {product.unit_price}
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
