import React from "react";
import Card from "./Card";
import SectionTitle from "./SectionTitle";
import { useSelector } from "react-redux";

const TodayDealsSection = () => {
  const { items: data } = useSelector((state) => state.todayDealsProduct);

  return (
    <>
      {data?.length > 0 ? (
        <>
          <div>
            <SectionTitle title={"Today Deals"} />
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-6 mt-5">
            {data?.map((product) => (
              <Card key={product?.id} product={product} />
            ))}
          </div>
        </>
      ) : null}
    </>
  );
};

export default TodayDealsSection;
