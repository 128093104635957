import React, { useEffect, useState } from "react";
import Card from "./Card";

import {
  A11y,
  Navigation,
  HashNavigation,
  Pagination,
  Scrollbar,
  Grid,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/hash-navigation";
import SectionTitle from "./SectionTitle";

const RelatedProduct = ({ singleProduct }) => {
  // const { items: data } = useSelector((state) => state.relatedProduct);
  const [relatedProduct, setRelatedProduct] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/relatedproductdata/${singleProduct?.product?.id}`
        );
        const data = await res.json();
        setRelatedProduct(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [singleProduct?.product?.id]);

  return (
    <>
      {relatedProduct?.length > 0 && (
        <div key={relatedProduct.id} className="">
          <div className="my-5">
            <SectionTitle title={"Related Product"} />
          </div>
          <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              HashNavigation,
              Grid,
            ]}
            spaceBetween={25}
            // slidesPerView={6}
            // navigation
            hashNavigation
            className="w-full h-full"
            scrollbar={{ draggable: true }}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 10,
              },

              425: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 25,
              },
              1440: {
                slidesPerView: 5,
                spaceBetween: 25,
              },
            }}
          >
            <div>
              {relatedProduct?.map((product) => (
                <SwiperSlide>
                  <Card key={product.id} product={product} />
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      )}
    </>
  );
};

export default RelatedProduct;
