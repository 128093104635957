import { useSelector } from "react-redux";

import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const BannerSlider = () => {
  const { items: data } = useSelector((state) => state.banner);

  return (
    <>
      {data.length > 0 && (
        <Carousel
          // infiniteLoop
          infiniteLoop
          autoPlay={true}
          interval="1000"
          autoFocus={true}
          emulateTouch={true}
          // transitionTime="1000"
          className="slider-banner"
        >
          {data.slice(0, 3).map((banner) => (
            <div key={banner.id}>
              <div className="w-full h-full  overflow-hidden">
                <img
                  src={`${process.env.REACT_APP_URL}/public/uploads/slider/${banner?.slider}`}
                  alt={banner.alt}
                  className={`h-full w-full object-cover`}
                />
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default BannerSlider;
