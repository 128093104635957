import React, { useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Parser } from "html-to-react";

import ProductDetailsCarousel from "../components/ProductDetailsCarousel";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  AiOutlineMinus,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { currencyFormatter } from "../utils/currencyFormatter";
import { BsMessenger, BsPlus, BsWhatsapp } from "react-icons/bs";
import RelatedProduct from "../components/RelatedProduct";
import {
  addToSingleCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";
import ProductImageCarasol from "../components/ProductImageCarasol";
import { toast } from "react-toastify";
import ProductCard from "../components/ProductCard";
import { IoLogoWhatsapp } from "react-icons/io";
import ImageComp from "../components/ImageComp";
import { Carousel } from "react-responsive-carousel";
import TagManager from "react-gtm-module";
const ProductDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { items: data } = useSelector((state) => state.products);

  const [singleProduct, setSingleProduct] = useState({});
  const [cartQuantity, setCartQuantity] = useState(1);
  // const [modalVisible, setModalVisible] = useState(false);
  const [imageThamb, setImageThamb] = useState([]);

  const [color, setColor] = useState([]);

  const [size, setSize] = useState([]);

  const [colorAtr, setColorAtr] = useState(null);
  const [sizeAtr, setSizeAtr] = useState(null);
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const singleProduct = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-products/${id}`
      );
      return setSingleProduct(res.data);
    };
    singleProduct();
  }, [id]);

  const handleColorChange = (event) => {
    setColorAtr(event.target.value);
  };
  const handleSizeChange = (event) => {
    setSizeAtr(event.target.value);
  };

  const handleModalOpen = () => {
    setModal(!modal);
  };

  const handleClose = () => {
    setModal(!modal);
  };

  useEffect(() => {
    const imageThambs = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-thumb/${singleProduct?.product?.id}}`
      );

      return setImageThamb(res.data);
    };
    imageThambs();
  }, [singleProduct?.product?.id]);

  useEffect(() => {
    const colors = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-color/${singleProduct?.product?.color}`
      );

      return setColor(res?.data);
    };
    colors();
    const sizes = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-size/${singleProduct?.product?.size}`
      );

      return setSize(res?.data);
    };
    sizes();
  }, [singleProduct?.product?.color, singleProduct?.product?.size]);

  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity((prev) => prev + 1);
    toast.warn("Quantity Increased", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);
  const handleAddToCart = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "add_to_cart", // Event name
        buttonName: "add_to_cart", // Additional data

        ecommerce: {
          currency: "BDT",
          value:
            singleProduct.product.after_discount > 0
              ? singleProduct.product.after_discount
              : singleProduct.product.unit_price,
          itmes: [
            {
              item_name: singleProduct.product.name,
              item_id: singleProduct.product.id,
              price:
                singleProduct.product.after_discount > 0
                  ? singleProduct.product.after_discount
                  : singleProduct.product.unit_price,
              discount_price: singleProduct.product.after_discount,
              item_category: singleProduct.product.category_name,
              item_subcategory: singleProduct.product.subcategory_name,
              item_discount:
                singleProduct.product.unit_price -
                singleProduct.product.after_discount,
              // quantity: singleProduct.product.cartQuantity,
            },
          ],
        },
      },
    });
    if (
      singleProduct?.product?.min_qty <= cartQuantity &&
      cartQuantity <= singleProduct?.product?.current_stock
    ) {
      dispatch(
        addToSingleCart({
          ...singleProduct.product,
          colorAtr,
          sizeAtr,
          cartQuantity,
        })
      );
    } else {
      if (singleProduct?.product?.min_qty > cartQuantity) {
        toast.warn(
          `please fullfil the min qty ${singleProduct?.product?.min_qty}`,
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        toast.warn(`Not Enough Product in Our Stock`, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const handleBuyNow = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "begin_checkout", // Event name
        buttonName: "begin_checkout", // Additional data
        ecommerce: {
          currency: "BDT",
          value:
            singleProduct.product.after_discount > 0
              ? singleProduct.product.after_discount
              : singleProduct.product.unit_price,
          itmes: [
            {
              item_name: singleProduct.product.name,
              item_id: singleProduct.product.id,
              price:
                singleProduct.product.after_discount > 0
                  ? singleProduct.product.after_discount
                  : singleProduct.product.unit_price,
              discount_price: singleProduct.product.after_discount,
              item_category: singleProduct.product.category_name,
              item_subcategory: singleProduct.product.subcategory_name,
              item_discount:
                singleProduct.product.unit_price -
                singleProduct.product.after_discount,
              quantity: singleProduct.product.cartQuantity,
            },
          ],
        },
      },
    });
    if (
      singleProduct?.product?.min_qty <= cartQuantity &&
      cartQuantity <= singleProduct?.product?.current_stock
    ) {
      dispatch(removeAllFromCart());
      dispatch(
        addToSingleCart({
          ...singleProduct.product,
          colorAtr,
          sizeAtr,
          cartQuantity,
        })
      );
      navigate("/checkout");
    } else {
      if (singleProduct?.product?.min_qty > cartQuantity) {
        toast.warn(
          `please fullfil the min qty ${singleProduct?.product?.min_qty}`,
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        toast.warn(`Not Enough Product in Our Stock`, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const htmlParser = new Parser();

  // {modalVisible && (
  //   <>
  //
  //   </>
  // )}

  return (
    <>
      {modal ? (
        <>
          <ImageComp
            singleProduct={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
            handleClose={handleClose}
            imageThamb={imageThamb}
          />
        </>
      ) : (
        <>
          <div className="mt-8 px-3">
            <div className="grid grid-cols-2 md:grid-cols-9 gap-10">
              <div className="left image col-span-2  md:col-span-9 lg:col-span-3">
                <button onClick={handleModalOpen}>
                  {/* <div className="">
                    <img
                      src={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div> */}
                  {data.length > 0 && (
                    <Carousel
                      // infiniteLoop
                      infiniteLoop
                      autoPlay={true}
                      interval="1000"
                      autoFocus={true}
                      emulateTouch={true}
                      // transitionTime="1000"
                    >
                      {imageThamb.map((image) => (
                        <div key={image.id}>
                          <div className="w-full h-[30rem]  overflow-hidden">
                            <img
                              src={`${process.env.REACT_APP_URL}/uploads/product/${image.icon}`}
                              alt={image.alt}
                              className={`h-full w-full object-cover`}
                            />
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  )}
                </button>
              </div>
              <div className="right flex flex-col gap-5 col-span-2  md:col-span-9  lg:col-span-6">
                <div className="font-semibold text-lg lg:text-3xl">
                  {singleProduct?.product?.name}
                </div>

                <div></div>
                <div className="flex gap-5">
                  <span className="font-semibold line-through text-[#FA5303] decoration-200">
                    ৳ {singleProduct?.product?.unit_price}
                  </span>
                  <div className="font-medium text-2xl">
                    {singleProduct?.product?.after_discount > 0 ? (
                      <>
                        ৳{" "}
                        {+singleProduct?.product?.after_discount * cartQuantity}
                      </>
                    ) : (
                      <>
                        ৳ {+singleProduct?.product?.unit_price * cartQuantity}
                      </>
                    )}
                  </div>
                </div>
                <div className="flex gap-3">
                  <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium  uppercase items-center">
                    Quantity :
                  </span>
                  <div className="flex items-center gap-4 ">
                    <button
                      className=" bg-slate-500/30 rounded-full flex justify-center items-center font-semibold text-xs h-5 w-5 hover:bg-[#F4891F] hover:text-white duration-500"
                      onClick={() => handleDecrease(singleProduct?.product)}
                    >
                      <AiOutlineMinus />
                    </button>
                    <input
                      type="number"
                      value={cartQuantity}
                      onChange={(e) => setCartQuantity(e.target.value)}
                      className="w-20 text-center "
                    />

                    <button
                      className=" bg-slate-500/30 h-5 w-5  rounded-full flex justify-center items-center hover:bg-[#F4891F] hover:text-white duration-500"
                      onClick={() => handleIncrease(singleProduct?.product)}
                    >
                      <BsPlus />
                    </button>
                  </div>

                  {/* <span>{singleProduct?.product?.min_qty}</span> */}
                </div>

                <div className="stoke-status  text-[#16995D]">
                  {singleProduct?.product?.current_stock > 0 && (
                    <span className="text-[1.4rem]">In stock</span>
                  )}
                </div>
                <div className="stoke-status mt-1 text-red-900">
                  {singleProduct?.product?.current_stock <= 0 && (
                    <span className="text-[2rem]">Out of Stock</span>
                  )}
                </div>

                <div>
                  {size?.length > 0 && (
                    <div className="color-atr flex items-center justify-start gap-7 mb-2">
                      <h4 className="text-2xl  ">
                        <span>Size</span>
                        <span>:</span>
                      </h4>
                      <div className="flex flex-wrap items-center justify-start gap-4">
                        {size?.map((s) => (
                          <span key={s.id} className="flex">
                            <input
                              type="checkbox"
                              value={s.value}
                              checked={sizeAtr === s.value}
                              onChange={handleSizeChange}
                              className="w-10"
                            />
                            <label className="">{s.value}</label>
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  {color?.length > 0 && (
                    <div className="color-atr flex items-center justify-start  gap-3 mb-2">
                      <h4 className="text-2xl  ">
                        <span>Color</span>
                        <span>:</span>
                      </h4>
                      <div className="flex flex-wrap items-center justify-start gap-4">
                        {color?.map((s) => (
                          <span key={s.id} className="flex">
                            <input
                              type="checkbox"
                              value={s.value}
                              checked={colorAtr === s.value}
                              onChange={handleColorChange}
                              className="w-10"
                            />
                            <label className="">{s.value}</label>
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="grid grid-cols-2 gap-2 text-white">
                  <div className="col-span-2 flex gap-2">
                    <button
                      onClick={handleBuyNow}
                      // disabled={cartQuantity < singleProduct?.product?.min_qty}
                      className="w-full py-3  px-5 bg-[#F4891F] rounded flex items-center justify-center"
                    >
                      <span className="text-xl">অর্ডার করুন।</span>
                    </button>

                    <button
                      onClick={handleAddToCart}
                      className="w-full py-3 px-5 bg-[#1C753B] rounded flex items-center justify-center"
                    >
                      Add To Cart
                    </button>
                  </div>

                  <a
                    className="w-full py-2 px-5 bg-blue-500 rounded hover:bg-[#1C753B] duration-300 col-span-2 flex flex-col items-center justify-center"
                    href="tel://+8801935381540"
                  >
                    <span>কল করতে ক্লিক করুন </span>
                    <span>+8801935-381540</span>
                  </a>

                  <Link
                    to={`https://m.me/774828232889725`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full py-2 px-5 bg-blue-500 rounded hover:bg-[#1C753B] duration-300 col-span-2 flex flex-col items-center justify-center"
                  >
                    <span>বিস্তারিত জানতে মেসেজ করুন</span>
                    <span className="text-xl">
                      <BsMessenger />
                    </span>
                  </Link>

                  <Link
                    to={`https://wa.me/+8801935381540`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full py-2 px-5 bg-blue-500 rounded hover:bg-[#1C753B] duration-300 col-span-2 flex flex-col items-center justify-center"
                  >
                    <span>WhatsApp</span>
                    <span className="text-2xl">
                      <IoLogoWhatsapp />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="details">
              <div className="my-5">
                <SectionTitle title={"Discription"} />
              </div>

              <div className="description w-[100%] overflow-x-scroll">
                <span>
                  {htmlParser.parse(singleProduct?.product?.description)}
                </span>
              </div>
            </div>
            <div className="mb-40">
              <RelatedProduct singleProduct={singleProduct} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductDetails;
