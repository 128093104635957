import SectionTitle from "./SectionTitle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Slider from "./slider/Slider";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination, Navigation } from "swiper";

const CategoryCard = () => {
  const { items: data } = useSelector((state) => state.category);

  return (
    <div className="max-w-[100rem] my-5">
      {/* <SectionTitle title={"Categories"} /> */}
      <div className="w-full bg-white">
        <Swiper
          spaceBetween={30}
          // centeredSlides={true}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          // pagination={{
          //   clickable: true,
          // }}
          // navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          // onAutoplayTimeLeft={onAutoplayTimeLeft}
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            330: {
              slidesPerView: 3,
              spaceBetween: 10,
            },

            450: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 25,
            },
            1024: {
              slidesPerView: 7,
              spaceBetween: 25,
            },
            1440: {
              slidesPerView: 9,
              spaceBetween: 25,
            },
          }}
        >
          {data?.map((category) => (
            <SwiperSlide key={category.id}>
              <Link
                to={`/categorypage/${category.id}`}
                className="category-card hover:shadow-md duration-300  rounded-md w-full h-full flex flex-col items-center justify-center"
                key={category.id}
              >
                <div className="category_image_container  flex items-center justify-center mx-auto py-2">
                  <div className="w-12 h-12 rounded-full overflow-hidden flex items-center justify-center">
                    <img
                      src={`${process.env.REACT_APP_URL}/uploads/category/${category?.banner}`}
                      alt=""
                      className="object-cover w-full h-full"
                    />
                  </div>
                </div>
                <div className="category_content relative overflow-hidden">
                  <p className="text-center mt-[10px] text-[#212121] ">
                    {category?.name}
                  </p>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CategoryCard;
