import { useSelector } from "react-redux";
// import SectionTitle from "./SectionTitle";
import Card from "./Card";

const StormOffer = () => {
  const { items: data } = useSelector((state) => state.stormOffer);

  return (
    <>
      {data.length > 0 ? (
        <div>
          {/* <SectionTitle title={"Storm Offer"} /> */}
          <div className="w-full ">
            <img
              src="https://res.cloudinary.com/divxqgoph/image/upload/v1707891077/asp-online/free_Delivery_cqsons.png"
              alt="storm offer"
              className="w-full h-full object-contain"
            />
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-6 mt-5">
            {data?.map((product) => (
              <Card key={product?.id} product={product} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default StormOffer;
