import React from "react";
import Card from "./Card";
import { useSelector } from "react-redux";
import SectionTitle from "./SectionTitle";

const TrandingSection = () => {
  const { items: data } = useSelector((state) => state.trandingProduct);

  return (
    <div className="my-5">
      <div>
        <SectionTitle title={"Tranding Product"} />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5  2xl:grid-cols-6 mt-5">
        {data?.map((product) => (
          <Card key={product?.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default TrandingSection;
