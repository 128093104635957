import CategoryCard from "../components/CategoryCard";

import ProductCard from "../components/ProductCard";

import StormOffer from "../components/StormOffer";
import TodayDealsSection from "../components/TodayDealsSection";
import TrandingSection from "../components/TrandingSection";
import HeroSections from "../components/sections/HeroSections";

// import ReviewCardPage from "./ReviewCardPage";

const Home = () => {
  // const [categoryProduct, setCategoryProduct] = useState([]);
  // console.log(categoryProduct);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await fetch(`${process.env.REACT_APP_MEHENDI_REVIEW_API}`);
  //       const data = await res.json();
  //       setCategoryProduct(data);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   fetchData();
  // }, []);

  return (
    <div className="">
      <HeroSections />
      {/* <CategoryCard /> */}

      <CategoryCard />

      <TrandingSection />
      <StormOffer />
      <TodayDealsSection />

      <ProductCard />
    </div>
  );
};

export default Home;
