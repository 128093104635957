import React from "react";

import { useSelector } from "react-redux";

import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const MiniSlider = () => {
  const { items: data } = useSelector((state) => state.banner);

  // const swiper = new Swiper(".sample-slider", {
  //   loop: true,
  //   autoplay: {
  //     //add
  //     delay: 0, //add
  //   }, //add
  // });
  return (
    <>
      {data.length > 0 && (
        <Carousel
          // infiniteLoop
          infiniteLoop
          autoPlay={true}
          interval="1000"
          autoFocus={true}
          axis="vertical"
          emulateTouch={true}
          // transitionTime="1000"
        >
          {data.slice(3, 6).map((banner) => (
            <div key={banner?.id}>
              <div className="w-auto h-auto overflow-hidden">
                <img
                  src={`${process.env.REACT_APP_URL}/public/uploads/slider/${banner?.slider}`}
                  alt={banner?.alt}
                  className={`h-full w-full object-cover`}
                />
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default MiniSlider;
