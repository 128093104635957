import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  // const { items: data } = useSelector((state) => state.category);
  const { items: footerData } = useSelector((state) => state.footerDetails);

  const { items: logo } = useSelector((state) => state.logo);
  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#1C753B" : null,
    };
  };
  return (
    <div className="sticky bg-[#FFFFFF] text-slate-50 md:px-10 md:py-8 p-4 mt-20  overflow-x-hidden">
      <div className="flex flex-col lg:grid lg:grid-cols-10 justify-between ">
        <div className="col-span-5">
          <span className="">
            <span className="flex flex-col mb-5">
              <h4 className="mb-2">ASP Online Market</h4>

              <p>
                Feel the Most Trusted Online Shopping Experience in Bangladesh.
              </p>
            </span>
            <div className="flex flex-col">
              <h2 className="text-md mb-4 font-light">
                Subscribe to Our Newsletter
              </h2>
              <form>
                <div className="flex flex-col 2xl:flex-row items-start gap-2 mb-4">
                  {/* <label className="sr-only" htmlFor="email">
                Email Address
              </label> */}
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your Email"
                    className="bg-slate-50 rounded-lg py-2 px-4 text-white w-[15rem]"
                  />
                  <button
                    type="submit"
                    className="bg-black text-white rounded-lg py-2 px-4"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </span>
          <img
            src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
            className="w-[14rem] mb-5"
            alt=""
          />

          <div className="flex  items-center gap-4">
            <div className="flex items-center">
              <Link
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400 mr-4"
              >
                <FaFacebook />
              </Link>
              <Link
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:hover:text-blue-400 mr-4"
              >
                <FaTwitter />
              </Link>
              <Link
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-red-400"
              >
                <FaInstagram />
              </Link>
            </div>
          </div>
        </div>
        <div className=" flex flex-col col-span-5 md:flex-row items-start justify-between mt-4 md:mt-0 gap-10 2xl:gap-32">
          <div className="flex flex-col">
            <h2 className="text-md mb-2">Useful Links</h2>
            <NavLink
              style={navActive}
              to={"/"}
              end
              className=" inline-block text-sm font-light text-slate-50 "
            >
              Home
            </NavLink>

            <Link
              to="terms"
              className=" inline-block text-sm font-light text-slate-50 "
            >
              Terms & conditions
            </Link>

            <Link
              to="returnpolicy"
              className=" inline-block text-sm font-light text-slate-50"
            >
              Return policy
            </Link>
            <Link
              to="supportpolicy"
              className=" inline-block text-sm font-light text-slat NavLink
            "
            >
              Support Policy
            </Link>
            <Link
              className=" inline-block text-sm font-light text-slat NavLink
            "
              to="privacypolicy"
            >
              Privacy policy
            </Link>
          </div>

          <div className="flex flex-col ">
            <h2 className="text-md mb-2">Company</h2>

            <Link
              to="about"
              className=" inline-block text-sm font-light text-slate-50"
            >
              About Us
            </Link>
            <Link
              to="contact"
              className=" inline-block text-sm font-light text-slat NavLink"
            >
              Contact
            </Link>
          </div>
          <div className="flex flex-col ">
            <h2 className="text-md mb-2">Contact info</h2>
            <Link className=" inline-block text-sm font-light text-slate-50 ">
              East Delpara, Kutubpur, Fatullah, Narayanganj-1421
            </Link>

            <a
              href="tel://+8801935-381540"
              className=" inline-block text-sm font-light text-slate-50"
            >
              Phone: +8801935-381540
            </a>
            <a
              href="mailto:info@asponlinemarket.com"
              className="inline-block text-sm font-light text-slat NavLink"
            >
              Email: info@asponlinemarket.com
            </a>
          </div>
        </div>
      </div>
      <footer className="footer  flex items-center justify-between    gap-5  text-slate-50 ">
        <div className="flex justify-center">
          <p className="text-slate-50 text-sm font-light mt-5">
            &copy; {new Date().getFullYear()} Reserved by ASP Online Market
          </p>
        </div>
        <div className="image-container w-72 2xl:w-[30rem] ">
          <img
            src="https://www.old.mehendibymimi.com/public/uploads/all/lnfIO3PdB1OE45OgRLmHu8fZdLRselIYRdKpAyam.png"
            alt=""
          />
        </div>

        <a href="https://softcreation.tech/" target="_blank">
          <span>
            Developed by
            <span className="text-blue-500">Soft Creation Limited</span>
          </span>
        </a>
      </footer>
    </div>
  );
};

export default Footer;
