import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import OrganicMehendi from "./pages/OrganicMehendi";
import NailMehendi from "./pages/NailMehendi";

import AddToCart from "./pages/AddToCart";
import TermsConditions from "./pages/TermsConditions";
import ReturnPolicy from "./pages/ReturnPolicy";
import SupportPolicy from "./pages/SupportPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import OrganicHennaPowder from "./pages/OrganicHennaPowder";
import Checkout from "./pages/Checkout";
import { useSelector } from "react-redux";
import SuccessPage from "./pages/SuccessPage";
import MehendiReview from "./pages/MehendiReview";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IndianMehendi from "./pages/IndianMehendi";
import MehendiArt from "./pages/MehendiArt";
import { useState } from "react";
import CartButton from "./components/CardButton";
import AddToCartCom from "./components/AddToCartCom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import SellCondition from "./pages/SellCondition";
import ReviewCardPage from "./pages/ReviewCardPage";
import BlogPage from "./pages/BlogPage";
import CategoryPage from "./pages/CategoryPage";
import CategorySection from "./components/CategorySection";
import SubCategoryPage from "./pages/SubCategoryPage";
import SideBar from "./components/SideBar";
import WholeSalePage from "./pages/WholeSalePage";
import OfferPage from "./pages/OfferPage";
import MobileNav from "./components/MobileNav";
import Search from "./components/SearchField";
import ShopPage from "./pages/ShopPage";
import ImageComp from "./components/ImageComp";

function App() {
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  // const [loading, setLoading] = useState(false);

  const [orderId, setOrderId] = useState("");
  const [state, setState] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const handleAddToCart = () => {
    setState(!state);
  };

  // const handleModalOpen = () => {
  //   setModalVisible(true);
  // };
  const [open, setOpen] = useState("");
  const [search, setSearch] = useState(false);

  const handleOpen = (value) => {
    setOpen(open === value ? "" : value);
  };

  const { items: category } = useSelector((state) => state.category);
  const { items: subcategories } = useSelector((state) => state.subCategory);

  const getSubcategoriesByCategoryId = (categoryId) => {
    return subcategories.filter(
      (subcategory) => +subcategory.category_id === categoryId
    );
  };

  const [sideNav, setSideNav] = useState(false);

  const handleClose = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    setSideNav(() => !sideNav);
  };
  const handleModalClose = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    setModalVisible(() => !modalVisible);
  };

  const handleOverlay2 = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    setSearch(() => !search);
  };

  const handleMenu = () => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";

    document.body.style.left = 0;
    document.body.style.right = 0;
    document.body.style.bottom = 0;
    setSideNav(() => !sideNav);
  };
  const handleModalOpen = () => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";

    document.body.style.left = 0;
    document.body.style.right = 0;
    document.body.style.bottom = 0;
    setModalVisible(() => !modalVisible);
  };

  return (
    <>
      {sideNav && (
        <>
          <div
            onClick={handleClose}
            className="bg-black/40   z-[9999]  fixed top-0 left-0 right-0 bottom-0 w-full h-full"
          ></div>
          <div className="flex justify-center items-center min-w-full">
            <SideBar handleClose={handleClose} />
          </div>
        </>
      )}

      {search && (
        <>
          <div
            onClick={handleOverlay2}
            className="bg-black/40   z-[9998]  fixed top-0 left-0 right-0 bottom-0 w-full h-full"
          ></div>
          <div className="flex justify-center items-center min-w-full">
            <Search handleClose={handleClose} />
          </div>
        </>
      )}

      <div className=" bg-[#F2F3F8]">
        <div className="min-h-screen">
          <Navbar handleMenu={handleMenu} />

          <ToastContainer />
          <ScrollToTop>
            <div className="block md:grid md:grid-cols-12 gap-2">
              {/* cat */}
              <div className="category-overflow  h-[90vh] sticky overflow-y-scroll top-24  md:col-span-3 lg:col-span-2   hidden md:block">
                <CategorySection
                  handleOpen={handleOpen}
                  getSubcategoriesByCategoryId={getSubcategoriesByCategoryId}
                  subcategories={subcategories}
                  category={category}
                  open={open}
                />
              </div>
              {/* other */}
              <div className="md:col-span-9 lg:col-span-10">
                <Routes>
                  <Route path={"/"} element={<Home />} />
                  <Route path="/productdetails" element={<ProductDetails />} />
                  <Route
                    exact
                    path="/productdetails/:id"
                    element={
                      <ProductDetails
                        modalVisible={modalVisible}
                        handleModalOpen={handleModalOpen}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/laptop/:id"
                    element={<OrganicMehendi />}
                  />
                  <Route exact path="/desktop/:id" element={<NailMehendi />} />
                  <Route
                    exact
                    path="/smart-watch/:id"
                    element={<IndianMehendi />}
                  />
                  <Route path="monitor/:id" element={<OrganicHennaPowder />} />
                  {/* <Route path="/mehendi-review/:id" element={<MehendiReview />} /> */}
                  <Route path="/accessories/:id" element={<MehendiArt />} />
                  <Route path="/categorypage" element={<CategoryPage />} />
                  <Route path="/categorypage/:id" element={<CategoryPage />} />
                  <Route
                    path="/wholesaleproducts"
                    element={<WholeSalePage />}
                  />
                  <Route path="/offerproducts" element={<OfferPage />} />
                  <Route path="/subcategory" element={<SubCategoryPage />} />
                  <Route path="/shop" element={<ShopPage />} />
                  <Route
                    path="/subcategory/:id"
                    element={<SubCategoryPage />}
                  />
                  <Route path="/addtocart" element={<AddToCart />} />
                  <Route
                    path="/checkout"
                    element={
                      data?.length > 0 && <Checkout setOrderId={setOrderId} />
                    }
                  />

                  <Route
                    path="/successpage"
                    element={<SuccessPage orderId={orderId} />}
                  />
                  <Route path="/terms" element={<TermsConditions />} />
                  <Route path="/returnpolicy" element={<ReturnPolicy />} />
                  <Route path="/supportpolicy" element={<SupportPolicy />} />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/sellcondition" element={<SellCondition />} />
                  {/* <Route path="/review" element={<ReviewCardPage />} /> */}
                  <Route path="/blogs/:id" element={<BlogPage />} />
                </Routes>

                <button
                  onClick={handleAddToCart}
                  className="fixed top-[45%] right-0 z-[9997]"
                >
                  <CartButton data={data} cartTotalAmount={cartTotalAmount} />
                </button>
                {state && <AddToCartCom state={state} setState={setState} />}
                <MobileNav handleMenu={handleMenu} />
                <Footer />
              </div>
            </div>
          </ScrollToTop>
        </div>
      </div>
    </>
  );
}

export default App;
