import React, { useState } from "react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { Carousel } from "react-responsive-carousel";
import { BiLeftArrowAlt } from "react-icons/bi";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { RxCross1 } from "react-icons/rx";
import { RiFullscreenLine } from "react-icons/ri";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { MdFullscreenExit } from "react-icons/md";

const ImageComp = ({ handleClose, singleProduct, imageThamb }) => {
  const handle = useFullScreenHandle();

  return (
    <FullScreen handle={handle}>
      <div
        onClick={handleClose}
        className="bg-black/40   z-[9998]  fixed top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden overflow-y-scroll"
      ></div>

      <div className="p-5 z-[9999] bg-black  fixed  shadow-xl top-0 right-0 left-0 bottom-0 w-full h-full overflow-y-scroll">
        <div className="grid gap-5">
          <div className="nav flex gap-5 justify-end">
            {handle.active ? (
              <button>
                <MdFullscreenExit
                  onClick={handle.exit}
                  className="text-[2rem] text-white"
                />
              </button>
            ) : (
              <button>
                <RiFullscreenLine
                  onClick={handle.enter}
                  className="text-[2rem] text-white"
                />
              </button>
            )}

            <button>
              <RxCross1
                onClick={handleClose}
                // onClick={handle.enter}
                className="text-[2rem] text-white"
              />
            </button>
          </div>

          <div className="flex items-center justify-center">
            <Carousel
              // infiniteLoop
              infiniteLoop
              showArrows={true}
              // autoPlay={true}
              // interval="1000"
              autoFocus={true}
              emulateTouch={true}
              className="main-slide relative"
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${
                      hasPrev ? "absolute" : "hidden"
                    } top-0 bottom-0 left-0 flex justify-center items-center p-3 cursor-pointer z-[9999]`}
                    onClick={clickHandler}
                  >
                    <ArrowLeftIcon className="w-9 h-9 text-red-600" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasNext) => {
                return (
                  <div
                    className={`${
                      hasNext ? "absolute" : "hidden"
                    } top-0 bottom-0 right-0 flex justify-center items-center p-3 cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <ArrowRightIcon className="w-9 h-9 text-red-600" />
                  </div>
                );
              }}
            >
              {imageThamb.map((image, index) => (
                <div key={index} className="image">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/product/${image.icon}`}
                    alt="thamb pic"
                    className="h-[25rem] md:h-[50rem] lg:h-[35rem] xl:h-[40rem]  2xl:h-[50rem] object-cover"
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </FullScreen>
  );
};

export default ImageComp;
