import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

export const subCategoriesFatching = createAsyncThunk(
  "subCategory/subCategoriesFatching",
  async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/api-subcategories`
    );

    return res.data;
  }
);
export const subCategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(subCategoriesFatching.pending, (state, action) => {
      state.status = "loading...";
    });
    builder.addCase(subCategoriesFatching.fulfilled, (state, action) => {
      state.status = "";
      state.items = action.payload;
    });
    builder.addCase(subCategoriesFatching.rejected, (state, action) => {
      state.status = "Something Went Wrong";
    });
  },
});

export default subCategorySlice.reducer;
