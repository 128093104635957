import { currencyFormatter } from "../utils/currencyFormatter";
import { useDispatch } from "react-redux";
import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import TagManager from "react-gtm-module";
const Card = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [colorAtr, setColorAtr] = useState(null);
  const [sizeAtr, setSizeAtr] = useState(null);

  const viewItem = (product) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "view_item", // Event name
        buttonName: "viewitem", // Additional data

        ecommerce: {
          currency: "BDT",
          value:
            product.after_discount > 0
              ? product.after_discount
              : product.unit_price,
          itmes: [
            {
              item_name: product.name,
              item_id: product.id,
              price:
                product.after_discount > 0
                  ? product.after_discount
                  : product.unit_price,
              discount_price: product.after_discount,
              item_category: product.category_name,
              item_subcategory: product.subcategory_name,
              item_discount: product.unit_price - product.after_discount,
            },
          ],
        },
      },
    });

    navigate(`/productdetails/${product?.id}`);
  };
  const addToCartHandler = (product) => {
    dispatch(addtoCart(product));
    navigate("/checkout");
  };

  const [cartQuantity, setCartQuantity] = useState(1);

  const handleBuyNow = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "begin_checkout", // Event name
        buttonName: "begin_checkout", // Additional data
        currency: "BDT",
        value:
          product.after_discount > 0
            ? product.after_discount
            : product.unit_price,
        ecommerce: {
          itmes: [
            {
              item_name: product.name,
              item_id: product.id,
              price:
                product.after_discount > 0
                  ? product.after_discount
                  : product.unit_price,
              discount_price: product.after_discount,
              item_category: product.category_name,
              item_subcategory: product.subcategory_name,
              item_discount: product.unit_price - product.after_discount,
            },
          ],
        },
      },
    });
    dispatch(removeAllFromCart());
    dispatch(addToSingleCart({ ...product, cartQuantity, colorAtr, sizeAtr }));
    navigate("/checkout");
  };

  return (
    <div className=" group  border-2 duration-300">
      <div className="flex h-full flex-col gap-5 items-center justify-center p-3 bg-white border-2 group-hover:border-[#F4891F] duration-300">
        <Link
          onClick={() => viewItem(product)}
          className="grid gap-5"
          to={`/productdetails/${product?.id}`}
        >
          <div className="image-section">
            <div className=" h-auto 2xl:h-[15rem] w-auto overflow-hidden">
              <img
                src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          <div className="product-details flex flex-col justify-center items-center">
            <p className="font-semibold hover:text-[#1B763B] duration-300 h-12">
              {product?.name?.substring(0, 20)}..
            </p>
            <span className="text-base text-[#318B41] flex gap-2">
              {product?.discount > 0 ? (
                <>
                  <span className="font-semibold line-through text-[#FA5303] decoration-200">
                    ৳ {product?.unit_price}
                  </span>
                  <span className="font-semibold text-[#70c332] text-xl">
                    ৳ {product?.after_discount}
                  </span>
                </>
              ) : (
                <span className="font-semibold text-[#70c332] text-xl">
                  ৳ {product?.unit_price}
                </span>
              )}
            </span>
          </div>
        </Link>

        <div className="w-full bg-[#f48a1f88] flex items-center justify-center rounded-full group-hover:bg-[#F4891F] duration-300">
          <button
            onClick={handleBuyNow}
            className="py-2 px-5 text-xs font-semibold"
          >
            অর্ডার করুন
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
