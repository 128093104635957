import React from "react";

import Slider from "../slider/Slider";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper";

import "./heroProductCard.css";
const HeroProductCard = () => {
  const { items: data } = useSelector((state) => state.products);
  return (
    <Swiper
      spaceBetween={30}
      // centeredSlides={true}
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
      }}
      // pagination={{
      //   clickable: true,
      // }}
      // navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      // onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="mySwiper"
      breakpoints={{
        0: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        330: {
          slidesPerView: 3,
          spaceBetween: 10,
        },

        450: {
          slidesPerView: 4,
          spaceBetween: 25,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 25,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 25,
        },
        1440: {
          slidesPerView: 7,
          spaceBetween: 25,
        },
      }}
    >
      {data.map((product) => (
        <SwiperSlide key={product.id} className="swiper-card">
          <Link
            to={`/productdetails/${product?.id}`}
            className="relative rounded-t-md overflow-hidden flex items-center"
          >
            <div className="md:w-40 md:h-40 lg:w-60 lg:h-60 overflow-hidden">
              <img
                src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                alt="img"
                className="w-full h-full object-cover"
              />
            </div>
            <span className="bg-[#F7881F] text-white absolute bottom-0 rounded-t-2xl w-full p-1 flex items-center justify-center text-xs lg:text-sm">
              {product?.name?.substring(0, 10)}..
            </span>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HeroProductCard;
