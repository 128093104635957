import BannerSlider from "../../pages/BannerSlider";
import HeroProductCard from "../cards/HeroProductCard";
import MiniSlider from "../slider/MiniSlider";

const HeroSections = () => {
  return (
    <div className="flex flex-col  px-2">
      <div className="grid grid-cols-1 lg:grid-cols-[64%_36%] gap-5 mt-5 overflow-hidden">
        <div>
          <BannerSlider />
        </div>
        <div className="hidden lg:block">
          <MiniSlider />
        </div>
      </div>

      <div className="col-span-full">
        <HeroProductCard />
      </div>
    </div>
  );
};

export default HeroSections;
