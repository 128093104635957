import React from "react";
import SectionTitle from "../components/SectionTitle";

const About = () => {
  return (
    <div className="container mx-auto px-3">
      <SectionTitle title={"About"} />
      <div className="about">
        <div className="general">
          <p className="mb-2 md:text-[18px]">
            <br />
            ASP Online Market Feel the Most Trusted Online Shopping Experience
            in Bangladesh.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
