import { configureStore } from "@reduxjs/toolkit";

import productReducer, {
  productFetching,
} from "../features/products/productSlice";
import cartReducer from "../features/products/cartSlice";
import categoryReducer, {
  categoryFetching,
} from "../features/products/categorySlice";
import bestSellingReducer, {
  bestSellingFetching,
} from "../features/products/bestSellingSlice";
import footerDetailsReducer, {
  footerDetails,
} from "../features/products/footerAboutSlice";

import bannerReducer, { bannerFatching } from "../features/banner/bannerSlice";

import logoReducer, { logoFatching } from "../features/logo/logoSlice";
import homeBannerReducer, {
  homeBannerFetching,
} from "../features/banner/homeBannerSlice";

import subCategoryReducer, {
  subCategoriesFatching,
} from "../features/category/subCategorySlice";

import trandingReducer, {
  trandingProductFatching,
} from "../features/products/trandingSlice";

import todayDealsReducer, {
  todayDealsProductFatching,
} from "../features/products/todayDealsSlice";

import featuredProductReducer, {
  featuredProductFatching,
} from "../features/products/wholeSellSlice";
import offerProductReducer, {
  offerProductsFetching,
} from "../features/products/offerProductSlice";

import shippingChargeReducer, {
  shippingChargeFetching,
} from "../features/shipping/shipingCharge";
import stormOfferReducer, {
  stormOfferFatching,
} from "../features/products/stormOfferSlice";
export const store = configureStore({
  reducer: {
    //reducer in here
    products: productReducer,
    cart: cartReducer,
    category: categoryReducer,
    bestSell: bestSellingReducer,
    footerDetails: footerDetailsReducer,
    banner: bannerReducer,
    logo: logoReducer,
    homeBanner: homeBannerReducer,
    subCategory: subCategoryReducer,
    trandingProduct: trandingReducer,
    todayDealsProduct: todayDealsReducer,
    featuredProduct: featuredProductReducer,
    offerProduct: offerProductReducer,
    shippingCharge: shippingChargeReducer,
    stormOffer: stormOfferReducer,
  },
});

store.dispatch(productFetching());
store.dispatch(categoryFetching());

store.dispatch(bestSellingFetching());
store.dispatch(footerDetails());

store.dispatch(bannerFatching());
store.dispatch(logoFatching());
store.dispatch(homeBannerFetching());
store.dispatch(subCategoriesFatching());

store.dispatch(trandingProductFatching());
store.dispatch(todayDealsProductFatching());
store.dispatch(featuredProductFatching());
store.dispatch(offerProductsFetching());
store.dispatch(shippingChargeFetching());
store.dispatch(stormOfferFatching());
