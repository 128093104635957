import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

export const stormOfferFatching = createAsyncThunk(
  "stormOffer/stormOfferFatching",
  async () => {
    const res = await axios.get(`${process.env.REACT_APP_URL}/api-stormoffer`);
    return res.data;
  }
);

export const featuredProductSlice = createSlice({
  name: "stormOffer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(stormOfferFatching.pending, (state, action) => {
      state.status = "loading...";
    });
    builder.addCase(stormOfferFatching.fulfilled, (state, action) => {
      state.status = "";
      state.items = action.payload;
    });
    builder.addCase(stormOfferFatching.rejected, (state, action) => {
      state.status = "Something Went Wrong";
    });
  },
});

export default featuredProductSlice.reducer;
