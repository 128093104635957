import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation, Grid } from "swiper";
import "swiper/css/grid";

const Slider = ({
  children,
  slidesPerView,
  speed,
  navigation,
  breakpoints,
  grid,
}) => {
  return (
    <>
      <Swiper
        speed={speed}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        grid={grid}
        slidesPerView={slidesPerView}
        spaceBetween={10}
        navigation={navigation}
        modules={[Navigation, Autoplay, Grid]}
        breakpoints={breakpoints}
        className="mySwiper w-full h-full"
      >
        {children}
      </Swiper>
    </>
  );
};

export default Slider;
