import React from "react";

import { Link, NavLink } from "react-router-dom";

import { BsCart3 } from "react-icons/bs";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  // ListItemSuffix,
  // Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
// import {
//   PresentationChartBarIcon,
//   ShoppingBagIcon,
//   UserCircleIcon,
//   Cog6ToothIcon,
//   InboxIcon,
//   PowerIcon,
// } from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";

import { BiLeftArrowAlt } from "react-icons/bi";

import { IoMdClose } from "react-icons/io";
import { useState } from "react";

const SideBar = ({ handleClose }) => {
  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#083344" : null,
      Font: isActive ? "font-bold" : null,
    };
  };

  const [open, setOpen] = useState("");

  const handleOpen = (value) => {
    setOpen(open === value ? "" : value);
  };
  const { items: categories } = useSelector((state) => state.category);
  const { items: subcategories } = useSelector((state) => state.subCategory);
  const getSubcategoriesByCategoryId = (categoryId) => {
    return subcategories.filter(
      (subcategory) => +subcategory.category_id === categoryId
    );
  };

  return (
    <Card className=" filterSideNav-wrapper rounded-none   h-100vh z-[999999] w-full max-w-[18rem]  overflow-y-scroll fixed  left-0 bottom-0 top-0 p-2 shadow-xl shadow-blue-gray-900/5">
      <div className="grid gap-2 mb-4 mt-4 ">
        <Link onClick={handleClose} to={`/wholesaleproducts`}>
          Wholesale
        </Link>
        <Link onClick={handleClose} to={`/offerproducts`}>
          Offer
        </Link>
        <Link
          to={`${process.env.REACT_APP_URL}/user/register`}
          rel="noreferrer"
        >
          Dealer
        </Link>
        <Link
          to={`${process.env.REACT_APP_URL}/seller/register`}
          rel="noreferrer"
        >
          Seller
        </Link>
      </div>

      <div className="">
        <Typography variant="h5" color="blue-gray">
          <BiLeftArrowAlt onClick={handleClose} className="text-[1.5rem]" />
        </Typography>
      </div>
      <List>
        <div className="text-2xl text-[#70c332] mb-4">Category</div>
        {categories?.map((category, index) => (
          <Accordion
            key={index}
            open={open === index}
            icon={
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === index ? "rotate-180" : ""
                }`}
              />
            }
          >
            <ListItem className="p-0" selected={open === index}>
              <AccordionHeader
                onClick={() => handleOpen(index)}
                className="border-b-0 p-3"
              >
                <ListItemPrefix className="h-5 w-5">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/category/${category?.icon}`}
                    alt={category.alt}
                    className="w-full h-full object-cover"
                  />
                </ListItemPrefix>

                <Typography
                  color="blue-gray"
                  className="mr-auto font-normal hover:text-[#70c332]"
                >
                  {category.name}
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1">
              <List className="p-0">
                {getSubcategoriesByCategoryId(category.id).map(
                  (subcategory, subIndex) => (
                    <Link key={subIndex} to={`/subcategory/${subcategory.id}`}>
                      <ListItem onClick={handleClose}>
                        <ListItemPrefix>
                          <ChevronRightIcon
                            strokeWidth={3}
                            className="h-3 w-5"
                          />
                        </ListItemPrefix>

                        {subcategory.name}
                      </ListItem>
                    </Link>
                  )
                )}
              </List>
            </AccordionBody>
          </Accordion>
        ))}
      </List>
    </Card>
  );
};

export default SideBar;
