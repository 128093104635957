import React from "react";

const SectionTitle = ({ title }) => {
  return (
    <div className="w-full h-10 bg-white text-[#002C36] flex items-center justify-center">
      <h2 className="text-2xl">{title}</h2>
    </div>
  );
};

export default SectionTitle;
